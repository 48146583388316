






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Back extends Vue {
  @Prop() white!: boolean;

  onclick() {
    this.$emit('click');
  }
}
