
















import { Component, Prop, Vue } from 'vue-property-decorator';
import Car from './Car.vue';
import { userService } from '@/services/user.service';

@Component({
  components: {
    Car,
  },
})
export default class Trade extends Vue {
  isIpad = userService.isIpad;
  mounted() {
    console.log(this.themeType);
  }

  get themeType() {
    return this.$store.state.themeType;
  }

  carData = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        { url: require('@/assets/images/color_film/trade/taycan/1.png'), color: 11 },
        { url: require('@/assets/images/color_film/trade/taycan/5.png'), color: 10 },
        { url: require('@/assets/images/color_film/trade/taycan/4.png'), color: 17 },
        { url: require('@/assets/images/color_film/trade/taycan/3.png'), color: 23 },
        { url: require('@/assets/images/color_film/trade/taycan/2.png'), color: 13 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        { url: require('@/assets/images/color_film/trade/911/4.png'), color: 11 },
        { url: require('@/assets/images/color_film/trade/911/3.png'), color: 7 },
        { url: require('@/assets/images/color_film/trade/911/1.png'), color: 9 },
        { url: require('@/assets/images/color_film/trade/911/2.png'), color: 8 },
        { url: require('@/assets/images/color_film/trade/911/6.png'), color: 12 },
        { url: require('@/assets/images/color_film/trade/911/5.png'), color: 13 },
      ],
    },
    {
      current: 0,
      carname: '718',
      imgs: [
        { url: require('@/assets/images/color_film/trade/718/1.png'), color: 11 },
        { url: require('@/assets/images/color_film/trade/718/3.png'), color: 8 },
        { url: require('@/assets/images/color_film/trade/718/2.png'), color: 9 },
        { url: require('@/assets/images/color_film/trade/718/5.png'), color: 7 },
        { url: require('@/assets/images/color_film/trade/718/4.png'), color: 6 },
        { url: require('@/assets/images/color_film/trade/718/6.png'), color: 12 },
      ],
    },
    {
      current: 0,
      carname: 'panamera',
      imgs: [
        { url: require('@/assets/images/color_film/trade/panamera/4.png'), color: 11 },
        { url: require('@/assets/images/color_film/trade/panamera/2.png'), color: 10 },
        { url: require('@/assets/images/color_film/trade/panamera/1.png'), color: 5 },
        { url: require('@/assets/images/color_film/trade/panamera/3.png'), color: 6 },
      ],
    },
    {
      current: 0,
      carname: 'macan',
      imgs: [
        { url: require('@/assets/images/color_film/trade/macan/6.png'), color: 5 },
        { url: require('@/assets/images/color_film/trade/macan/2.png'), color: 10 },
        { url: require('@/assets/images/color_film/trade/macan/3.png'), color: 14 },
        { url: require('@/assets/images/color_film/trade/macan/4.png'), color: 13 },
        { url: require('@/assets/images/color_film/trade/macan/1.png'), color: 6 },
        { url: require('@/assets/images/color_film/trade/macan/5.png'), color: 7 },
      ],
    },
    {
      current: 0,
      carname: 'cayenne',
      imgs: [
        { url: require('@/assets/images/color_film/trade/cayenne/4.png'), color: 4 },
        { url: require('@/assets/images/color_film/trade/cayenne/2.png'), color: 6 },
        { url: require('@/assets/images/color_film/trade/cayenne/3.png'), color: 7 },
        { url: require('@/assets/images/color_film/trade/cayenne/1.png'), color: 14 },
      ],
    },
  ];
}
