























































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { pickBy, uniq, pick, sortBy } from 'lodash-es';
import { Dialog } from 'vant';
import Axios from 'axios';
import { userService } from '@/services/user.service';

// const plist = require('@/assets/addres.json');

@Component({})
export default class query extends Vue {
  isIpad = userService.isIpad;
  obj = {
    province: '',
    city: '',
    serviceCentre: '',
  };
  arr: any[] = [];

  plist: any = [];

  code = '';

  provinceList: any[] = [];
  cityList: any[] = [];
  serviceCentreList: any[] = [];

  objArrData: any[] = [];

  get queryFrom() {
    return this.$store.state.queryFrom;
  }

  routertop() {
    const pageName = this.queryFrom === 'Detail' ? 'Detail' : 'form';
    this.$store.commit('setCurrentPage', pageName);
    this.$store.commit('setQueryFrom', '');
  }

  getprovinceList() {
    console.log(this.plist);
    this.arr = this.plist.sort((a, b) => a.city.localeCompare(b.city));
    // console.log(this.arr)
    this.cityList = uniq(this.arr.map(i => i.city));
  }
  getCity(province) {
    const list = this.arr.filter(i => i.province === province);
    this.cityList = uniq(list.map(i => i.city));
    // this.carModelList = [];
    this.obj.city = '';
    this.obj.serviceCentre = '';
    this.serviceCentreList = [];

    // this.carSeries = series;
    // console.log(this.yearList);
  }
  getCentre(province, city) {
    const list = this.arr.filter(i => i.city === city);
    this.serviceCentreList = list.sort((a, b) => a.shopName.localeCompare(b.shopName));
    this.obj.serviceCentre = '';
  }
  moni(item) {
    if (item) {
      return item;
    }
    return '请选择';
  }
  submit() {
    if (this.obj.city !== '' && this.obj.serviceCentre !== '') {
      // let url = '/priceResults' + '?carObj=' + encodeURIComponent(this.obj);
      this.$store.commit('city', this.code);
      this.$store.commit('setCurrentPage', 'result');
    } else {
      Dialog.alert({
        message: '请输入您要查询的信息',
      }).then(() => {
        // on close
      });
    }

    return;
  }
  getAddres() {
    this.code = this.serviceCentreList.filter(x => x.shopName === this.obj.serviceCentre)[0].code;
    console.log(this.code);
  }

  created() {
    console.log('1212');
    Axios.get('https://event.fnws.top/Porsche-Static/dealers.json').then(res => {
      this.plist = res.data;
      this.getprovinceList();
    });
  }
}
