






































































import { userService } from '@/services/user.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Car from './Car.vue';
import CarFilled from './CarFilled.vue';

@Component({
  components: {
    Car,
    CarFilled,
  },
})
export default class Simple extends Vue {
  isIpad = userService.isIpad;
  mounted() {
    console.log(this.themeType);
  }

  get themeType() {
    return this.$store.state.themeType;
  }

  carData1 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        { url: require('@/assets/images/color_film/simple/taycan/6.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/taycan/7.png'), color: 21 },
        { url: require('@/assets/images/color_film/simple/taycan/1.png'), color: 19 },
        { url: require('@/assets/images/color_film/simple/taycan/5.png'), color: 18 },
        { url: require('@/assets/images/color_film/simple/taycan/2.png'), color: 17 },
        { url: require('@/assets/images/color_film/simple/taycan/4.png'), color: 16 },
        { url: require('@/assets/images/color_film/simple/taycan/3.png'), color: 15 },
      ],
    },
    {
      current: 0,
      carname: 'panamera',
      imgs: [
        { url: require('@/assets/images/color_film/simple/panamera/4.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/panamera/1.png'), color: 16 },
        { url: require('@/assets/images/color_film/simple/panamera/3.png'), color: 2 },
        { url: require('@/assets/images/color_film/simple/panamera/2.png'), color: 3 },
      ],
    },
  ];

  carData2 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        { url: require('@/assets/images/color_film/simple/taycan/26.png'), color: 6 },
        { url: require('@/assets/images/color_film/simple/taycan/22.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/taycan/21.png'), color: 21 },
        { url: require('@/assets/images/color_film/simple/taycan/25.png'), color: 10 },
        { url: require('@/assets/images/color_film/simple/taycan/23.png'), color: 17 },
        // { url: require('@/assets/images/color_film/simple/taycan/24.png'), color: 19 },
      ],
    },
    {
      current: 0,
      carname: 'panamera',
      imgs: [
        { url: require('@/assets/images/color_film/simple/panamera/21.png'), color: 6 },
        { url: require('@/assets/images/color_film/simple/panamera/24.png'), color: 10 },
        { url: require('@/assets/images/color_film/simple/panamera/25.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/panamera/23.png'), color: 5 },
        { url: require('@/assets/images/color_film/simple/panamera/22.png'), color: 11 },
      ],
    },
  ];

  carData3 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        { url: require('@/assets/images/color_film/simple/taycan/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/taycan/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: 'panamera',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/panamera/31.png'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: 'cayenne',
      imgs: [
        { url: require('@/assets/images/color_film/simple/cayenne/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/cayenne/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: 'macan',
      imgs: [
        { url: require('@/assets/images/color_film/simple/macan/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/macan/32.png'), color: 21 },
      ],
    },
  ];

  carData4 = [
    {
      current: 0,
      carname: 'macan',
      imgs: [
        { url: require('@/assets/images/color_film/simple/macan/44.png'), color: 20 },
        { url: require('@/assets/images/color_film/simple/macan/45.png'), color: 21 },
        { url: require('@/assets/images/color_film/simple/macan/42.png'), color: 1 },
        { url: require('@/assets/images/color_film/simple/macan/41.png'), color: 16 },
        { url: require('@/assets/images/color_film/simple/macan/43.png'), color: 22 },
      ],
    },
  ];
  carData5 = [
    {
      current: 0,
      carname: 'cayman',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/cayman/51.jpg'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/718/51.jpg'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/911/51.jpg'), color: 20 },
        { url: require('@/assets/images/color_film/simple/911/52.jpg'), color: 13 },
        { url: require('@/assets/images/color_film/simple/911/53.jpg'), color: 2 },
      ],
    },
    {
      current: 0,
      carname: 'cayenne',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/cayenne/51.jpg'), color: 20 },
      ],
    },
  ];
  carData6 = [
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/718/61.jpg'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/simple/911/61.jpg'), color: 11 },
        { url: require('@/assets/images/color_film/simple/911/62.jpg'), color: 7 },
      ],
    },
  ];
}
