import Vue, { DirectiveOptions } from 'vue';

// 触摸元素加class
Vue.directive('touchClass', {
  inserted(el: HTMLElement, binding) {
    el.addEventListener('touchstart', () => {
      el.classList.add(binding.value || 'touchstart');
    });
    el.addEventListener('touchend', () => {
      el.classList.remove(binding.value || 'touchstart');
    });
  },
} as DirectiveOptions);

// 延迟加on
Vue.directive('onDelay', {
  inserted(el: HTMLElement, binding) {
    setTimeout(() => {
      el.classList.add('on');
    }, binding.value);
  },
} as DirectiveOptions);

// 点击加on
Vue.directive('onAdd', {
  inserted(el: HTMLElement, binding) {
    const classList = binding.value.split(',');
    const items = classList.map(i => document.querySelector(i));

    el.addEventListener('click', () => {
      // console.log(item);
      items.map(i => i.classList.add('on'));
    });
  },
} as DirectiveOptions);

// 点击加on
Vue.directive('onRemove', {
  inserted(el: HTMLElement, binding) {
    const item = document.querySelector(binding.value);

    el.addEventListener('click', () => {
      // console.log(item);
      item.classList.remove('on');
    });
  },
} as DirectiveOptions);

// 过滤触摸
Vue.directive('disableTouch', {
  inserted(el: HTMLElement, binding) {
    el.addEventListener('touchmove', e => e.preventDefault());
  },
} as DirectiveOptions);
