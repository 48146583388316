class UserService {
  /** 是否ipad */
  isIpad = (window as any).innerWidth > 670 ? true : false;

}

/** 单例对象函数 */
const service = (() => {
  // 隐藏的Class的构造函数
  // function TodayCilpService() {}

  // 未初始化的单例对象
  let service: UserService;

  return {
    // 创建/获取单例对象的函数
    getInstance: () => {
      if (!service) {
        service = new UserService();
      }
      return service;
    },
  };
})();
export const userService = service.getInstance();
