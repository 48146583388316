

























import { Component, Prop, Vue } from 'vue-property-decorator';
import Trade from './Trade.vue';
import Race from './Race.vue';
import Simple from './Simple.vue';
import Back from './Back.vue';
import { APP_CONFIG } from '@/config';
import { userService } from '@/services/user.service';

@Component({
  components: {
    Trade,
    Race,
    Simple,
    Back,
  },
})
export default class Detail extends Vue {
  // mounted() {
  //   document.body.style.overflow = 'auto';
  // }

  isIpad = userService.isIpad;
  get themeType() {
    return this.$store.state.themeType;
  }

  back2Theme() {
    this.$store.commit('setCurrentPage', 'Theme');
  }

  go2Book() {
    window.location.href = APP_CONFIG.bookLink;
  }

  go2Query() {
    this.$store.commit('setQueryFrom', 'Detail');
    this.$store.commit('setCurrentPage', 'query');
  }
}
