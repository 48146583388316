<template>
  <div class="skip" @click="routerForm()">
    <span v-if="currentPage !== 'durable'" :class="{ on: currentPage === 'yellowingResistance' }">{{ txt }}</span>
    <span v-if="currentPage === 'durable'">下一步</span>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['currentPage']),
})
export default class Skip extends Vue {
  routerForm() {
    if (this.currentPage === 'Home') return this.$store.commit('setCurrentPage', 'seifHealing');
    this.$store.commit('setCurrentPage', 'form');
  }

  txt = '跳过';
}
</script>

<style lang="scss">
.skip {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  padding: 20px;
  span {
    color: #fff;
    font-size: 2vw;
    position: relative;
    &.on {
      color: #000;
    }
    &::before {
      content: '';
      position: absolute;
      left: -2vw;
      top: 50%;
      width: 1vw;
      height: 1vw;
      border-color: red;
      border-style: solid;
      border-width: 2px 0 0 2px;
      transform: translateY(-50%) rotate(135deg);
    }
  }
  img {
    width: 60px;
  }
  @media (max-width: 670px) {
    span {
      font-size: 16px;
      &::before {
        width: 8px;
        height: 8px;
        left: -15px;
      }
    }
  }
}
</style>
