<template>
  <div id="app" :class="{ on: init }">
    <div class="music-btn" :class="musicState" @click="toggleMusic()"></div>
    <transition :name="transitionName">
      <router-view class="view app-view"></router-view>
    </transition>
    <Footer />
    <div class="ipad-h" v-show="ipadType">
      <img src="@/assets/images/mobile-h.png" alt="" />
      <div class="text">请横屏观看</div>
    </div>
  </div>
</template>

<script>
import { wxConfig } from '@/services/wx';
import { utilsService } from './services/utils.service';

// import Footer from '@/components/footer.vue';

export default {
  name: 'app',
  data() {
    return {
      init: false,
      transitionName: '',
      music: {},
      musicState: '',
      ipadType: false,
    };
  },
  components: {
    // Footer,
  },
  methods: {
    toggleMusic() {
      this.musicState ? this.music.pause() : this.music.play();
    },
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width < height) {
        this.ipadType = true;
      } else {
        this.ipadType = false;
      }
    },
  },
  mounted() {
    wxConfig();
    if (document.documentElement.clientWidth > 500) {
      this.renderResize();
      window.addEventListener('resize', this.renderResize, false);
    }
  },
  created() {
    const mod = utilsService.getQueryString('mod');
    if (mod === 'colorfilm') {
      this.$store.commit('setCurrentPage', 'Theme');
    }
    setTimeout(() => {
      this.init = true;
    }, 200);
  },

  watch: {},
};
</script>

<style lang="scss" scope>
#app {
  opacity: 0;
  pointer-events: none;
  &.on {
    opacity: 1;
    pointer-events: auto;
  }
  .ipad-h {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 50%;
    }
    .text {
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
