import { utilsService } from './services/utils.service';
export const rootPath = '/2019/bbacalendar19';
const baseUrl = (window.location.href.match(/^http[^#?]+\//i) || [])[0] + 'index.html' || '';

const getBaseUrl = () => {
  let host = 'https://fc.fnws.top';
  if (/(localhost|10.0.0)/.test(baseUrl)) host = 'https://fc.fnws.top';
  if (/(fnws.top)/.test(baseUrl)) host = 'https://fc.fnws.top';
  return host;
};

const getJssdkHost = () => {
  let jssdkHost = 'https://pdsixd.porsche-cloud.com/auth/jssdk_config';
  if (/fnws.top/i.test(baseUrl)) jssdkHost = 'https://test.thefront.com.cn/porsche.pds/auth/jssdk_config';
  return jssdkHost;
};



const bookLink = 'https://myportal.porsche.cn/service_sab';

const HOST = getBaseUrl();
const jssdkHost = getJssdkHost();

export const APP_CONFIG = {
  baseUrl,
  jssdkHost,
  bookLink,
  HOST,
  Endpoint: {},
  version: '1.0.0',
};
