import Vue from "vue";
import VueJsonp from "vue-jsonp";
import wx from "weixin-js-sdk";
import { APP_CONFIG } from '@/config';
import Axios from 'axios';
import md5 from 'blueimp-md5';


Vue.use(VueJsonp);

function getSign(salt, timestamp) {
  const str = timestamp + salt;
  const n = parseInt(timestamp.charAt(9)) > 0 ? parseInt(timestamp.charAt(9)) : 1;
  return uMD5(str, n);
}
function uMD5 (str,n){
   if (n < 1) return str;
    return uMD5(md5(str), --n);
  };


export let shareInfo = {
  title: "漆面保护膜",
  desc: "隐形守护每一天",
  link: (window.location.href.match(/^http[^#?]+\//i) || [])[0],
  timeline: "隐形守护每一天",
  imgUrl: (window.location.href.match(/^http[^#?]+\//i) || [])[0] + require("@/assets/images/logo.jpg")
};

export const wxConfig = () => {
  const timestamp = Date.now().toString().slice(0, 10);
  const salt = md5(timestamp);
  const sign = getSign(salt, timestamp);

  // 请求接口返回分享信息
  const url = APP_CONFIG.jssdkHost+'?url='+window.location.href.split("#")[0]
  const config = {
    headers: {
      contentType: 'application/json',
      s: salt + timestamp + sign,
    }
};
Axios.get(url, config).then(res=>{
  console.log('分享',res);
  if(res.status === 200){
    
         wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ]
          });
          
          wx.ready(function() {
            wx.updateAppMessageShareData({
              title: shareInfo.title,
              desc: shareInfo.desc,
              imgUrl: shareInfo.imgUrl,
              link: shareInfo.link,
              success: function() {
              }
            });
            wx.updateTimelineShareData({
              title: shareInfo.title,
              desc: shareInfo.desc,
              imgUrl: shareInfo.imgUrl,
              link: shareInfo.link,
              success: function() {
              }
            });
  })}
}).catch(err=>{
  console.log('分享失败',err);
})

// Axios(config)
// .then(response => {
//     // 请求成功处理
//     console.log(response.data);
// })
// .catch(error => {
//     // 请求失败处理
//     console.error('Error fetching data: ', error);
// });

 


  // let url = "https://test.thefront.com.cn/porsche.pds/auth/jssdk_config";
  // let param = {
  //   request: {
  //     body: { url: window.location.href.split("#")[0] },
  //     head: {
  //       public_key: "1",
  //       secret_key: "1",
  //       timestamp: "1"
  //     }
  //   }
  // };
  // Vue.jsonp(url, param)
  //   .then(res => {
  //     console.log('分享',res);
  //     wx.config({
  //       debug: false,
  //       appId: res.signPackage.appId,
  //       timestamp: res.signPackage.timestamp,
  //       nonceStr: res.signPackage.nonceStr,
  //       signature: res.signPackage.signature,
  //       jsApiList: [
  //         "onMenuShareTimeline",
  //         "onMenuShareAppMessage",
  //         "updateAppMessageShareData",
  //         "updateTimelineShareData"
  //       ]
  //     });
  //     wx.ready(function() {
  //       wx.updateAppMessageShareData({
  //         title: shareInfo.title,
  //         desc: shareInfo.desc,
  //         imgUrl: shareInfo.imgUrl,
  //         link: shareInfo.link,
  //         success: function() {
  //         }
  //       });
  //       wx.updateTimelineShareData({
  //         title: shareInfo.title,
  //         desc: shareInfo.desc,
  //         imgUrl: shareInfo.imgUrl,
  //         link: shareInfo.link,
  //         success: function() {
  //         }
  //       });
  //     });
  //   })
  //   .catch(err => {
  //     console.error("错误", err);
  //   });
};
