



















import { Component, Prop, Vue } from 'vue-property-decorator';
import Back from './Back.vue';
import { userService } from '@/services/user.service';

@Component({
  components: {
    Back,
  },
})
export default class Theme extends Vue {
  mounted() {}
  isIpad = userService.isIpad;
  goDetail(name: string) {
    this.$store.commit('setThemeType', name);
    this.$store.commit('setCurrentPage', 'Detail');
  }
  back2Home() {
    this.$store.commit('setCurrentPage', 'Home');
  }
}
