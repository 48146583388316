





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { userService } from '@/services/user.service';

let startY: any = 0;
let startX = 0;

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class Index extends Vue {
  @Prop() private msg!: string;

  bgState = false;

  isIpad = userService.isIpad;

  mounted() {
    console.log(`userService`, userService.isIpad);
    setTimeout(() => {
      this.bgState = true;
    }, 1e3);
  }

  // touchstart(e) {
  //   e.preventDefault();
  //   startY = e.touches[0].clientY;
  //   startX = e.touches[0].clientX;
  // }
  // touchend(e) {
  //   e.preventDefault();
  //   // 上滑
  //   if (startY > e.changedTouches[0].clientY + 20) {
  //     this.$store.commit('setCurrentPage', 'Video');
  //   }
  // }
  mainRou() {
    if (window.location.host === 'test.fnws.top') {
      window.location.replace('https://test.fnws.top/Porsche-Productandservice/');
    } else {
      window.location.replace('https://aftersales.porsche-cloud.com/productandservice/?utm_source=bodyfilm&utm_medium=allinone');
    }
  }
  end(page = 'Video') {
    // console.log(e, page);

    // e.preventDefault();
    // // 右滑
    // if (e.changedTouches[0].clientX > startX + 20) {
    this.$store.commit('setCurrentPage', page);
    // console.log('11', page);
    // }
  }

  //
  themeSwiperOption: any = {
    // loop: true,
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: 1.6,
    coverflowEffect: {
      rotate: 0,
      stretch: this.isIpad ? 90 : -60,
      depth: 300,
      modifier: 1,
      slideShadows: false,
    },
  };

  realIndex = 0;
  slideChange() {
    this.realIndex = (this.$refs.themeSwiper as any).$swiper.realIndex;
  }
}
