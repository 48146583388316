<template>
  <div class="paint" v-onDelay="100">
    <div
      class="bg"
      @touchend="touchend"
      @touchmove="touchmove"
      @touchstart="touchstart"
      :style="{ opacity: `${value * 0.01}` }"
    ></div>
    <!-- :style="{ filter: `brightness(${value / 100})` }" -->

    <img v-onDelay="500" class="paint-txt mobile" src="@/assets/images/mobile/paint-txt.png" alt="" />
    <div class="tishi" :class="{ type: value === 0 }">
      <img v-onDelay="800" src="@/assets/images/paint-tishi.png" alt="" />
    </div>
    <div class="brightness" v-if="brightnessType">
      <div class="br"><span :style="{ width: `${value}%` }"></span></div>
      <!-- <img src="@/assets/images/brightness.png" alt=""> -->
    </div>
    <div class="skip" @click="routertop">
      <span>返回</span>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
// import Footer from '@/components/footer.vue';
@Component({
  // components: { Footer },
  //   computed: mapState(['currentPage']),
})
export default class paint extends Vue {
  value = 0;
  touchstartNum = 0;
  brightness = 0;
  brightnessType = false;
  touchstart(e) {
    this.brightnessType = true;
    this.touchstartNum = e.touches[0].clientY;
  }
  touchend() {
    this.brightnessType = false;
  }
  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }
  touchmove(e) {
    if (e.touches[0].clientY < this.touchstartNum) {
      this.value = this.value + 3.8;
      this.brightness++;
    } else {
      this.value = this.value - 3.8;
      this.brightness--;
    }
    if (this.value > 100) {
      this.value = 100;
    }
    if (this.value < 0) {
      this.value = 0;
    }

    if (this.brightness > 100) {
      this.brightness = 100;
    }
    if (this.brightness < 0) {
      this.brightness = 0;
    }
    this.touchstartNum = e.touches[0].clientY;
  }
}
</script>

<style lang="scss" scoped>
.paint {
  opacity: 0;
  transition: 1.5s ease;
  width: 100vw;
  height: 100vh;
  background: url('../assets/images/paint-bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  &.on {
    opacity: 1;
  }
  .skip {
    left: 20px;
    span {
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  .paint-txt {
    width: 50%;
    position: absolute;
    bottom: 30%;
    left: 10%;
    opacity: 0;
    transition: 1s ease;
    transform: translate(0, 30px);
    display: block;
    @media (min-width: 670px) {
      left: 50%;
      top: 13%;
    }
    &.on {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .bg {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/paint-bg-s.png') no-repeat;
    background-size: cover;
    background-position: center;

    position: relative;
  }
  @media (max-width: 670px) {
    background: url('../assets/images/mobile/paint-bg.jpg') top;
    background-size: 100%;
    // background-position: center;
    .bg {
      background: url('../assets/images/mobile/paint-bg-s.png') top;
      background-size: 100%;
    }
    .paint-txt {
      width: 100%;
      left: 0;
    }
  }
  .tishi {
    position: fixed;
    top: 18%;
    right: 0%;
    display: none;
    width: 50%;

    img {
      opacity: 0;
      width: 100%;
      // transform: translate(30px, 0);
      // transition: 1s ease;
    }
    .on {
      transform: translate(0, 0);
      opacity: 1;
      animation: myfirst3 5s;
    }
    &.type {
      display: block;
    }

    @media (max-width: 670px) {
      top: 18%;
      width: 100%;
      right: 0%;
    }
  }
  .brightness {
    position: fixed;
    width: 261px;
    height: 61px;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('../assets/images/brightness.png') no-repeat;
    background-size: 100%;
    @media (min-width: 670px) {
      top: 75%;
    }
    img {
      width: 100%;
    }
    .br {
      position: relative;
      width: 50%;
      height: 2px;
      background: rgba($color: #ccc, $alpha: 0.3);
      top: 50%;
      left: 30%;
      transform: translate(0, -50%);
      span {
        display: block;
        height: 2px;
        background: #fff;
      }
    }
  }
}
</style>
