


































































































































































import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { pickBy, uniq, pick, sortBy } from 'lodash-es';

import { Component, Vue } from 'vue-property-decorator';
import { APP_CONFIG } from '@/config';
import { userService } from '@/services/user.service';

const plist = require('@/assets/models.json');

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class FormBox extends Vue {
  isIpad = userService.isIpad;

  go2Book() {
    window.location.href = APP_CONFIG.bookLink;
  }

  go2Query() {
    this.$store.commit('setCurrentPage', 'query');
  }

  routertop() {
    this.$store.commit('setCurrentPage', 'durable');
  }

  getprovinceList() {
    this.arr = plist.sort((a, b) => a.series.localeCompare(b.series));
    // console.log(this.arr)
    this.seriesList = uniq(this.arr.map((i) => i.series));
    console.log(this.seriesList);
  }

  arr: any = [];
  seriesList: any = [];
  modelList: any = [];
  mealList: any = [];
  mealList2 = ['保险杠套餐', '内饰膜套餐', '基础套餐', '前车身套餐', '翼子板套餐', '整车套餐'];
  created() {
    this.getprovinceList();
  }

  imgRoute = 'cayman';
  obj = {
    series: '',
    model: '',
    meal: '',
  };

  getModel(series) {
    this.obj.model = '';
    this.obj.meal = '';
    this.mealList = [];

    const list = this.arr.filter((i) => i.series === series);
    this.modelList = list.sort((a, b) => a.model.localeCompare(b.model));
    console.log(this.modelList);
  }
  getMeal(meal) {
    if (!meal) return;
    this.mealList = this.mealList2;

    this.obj.meal = '';
    this.getImgRoute();
  }

  // 更换整组图片
  getImgRoute() {
    let name = this.modelList.filter((x) => x.model === this.obj.model)[0].name;
    console.log(name);

    this.swiperArr = {
      b1: require(`@/assets/images/swiper/${name}/b1.png`),
      b2: require(`@/assets/images/swiper/${name}/b2.png`),
      j1: require(`@/assets/images/swiper/${name}/j1.jpg`),
      j2: require(`@/assets/images/swiper/${name}/j2.jpg`),
      j3: require(`@/assets/images/swiper/${name}/j3.png`),
      n1: require(`@/assets/images/swiper/${name}/n1.jpg`),
      q1: require(`@/assets/images/swiper/${name}/q1.png`),
      y1: require(`@/assets/images/swiper/${name}/y1.png`),
      y2: require(`@/assets/images/swiper/${name}/y2.png`),
      z1: require(`@/assets/images/swiper/${name}/z1.png`),
      z2: require(`@/assets/images/swiper/${name}/z2.png`),
    };
    if (this.obj.model === 'Taycan') return;

    this.swiperArr.n2 = require(`@/assets/images/swiper/${name}/n2.jpg`);
  }

  swiperArr: any = {
    b1: require(`@/assets/images/swiper/${this.imgRoute}/b1.png`),
    b2: require(`@/assets/images/swiper/${this.imgRoute}/b2.png`),
    j1: require(`@/assets/images/swiper/${this.imgRoute}/j1.jpg`),
    j2: require(`@/assets/images/swiper/${this.imgRoute}/j2.jpg`),
    j3: require(`@/assets/images/swiper/${this.imgRoute}/j3.png`),
    n1: require(`@/assets/images/swiper/${this.imgRoute}/n1.jpg`),
    n2: require(`@/assets/images/swiper/${this.imgRoute}/n2.jpg`),
    q1: require(`@/assets/images/swiper/${this.imgRoute}/q1.png`),
    y1: require(`@/assets/images/swiper/${this.imgRoute}/y1.png`),
    y2: require(`@/assets/images/swiper/${this.imgRoute}/y2.png`),
    z1: require(`@/assets/images/swiper/${this.imgRoute}/z1.png`),
    z2: require(`@/assets/images/swiper/${this.imgRoute}/z2.png`),
  };

  swiperOption: any = {
    slidesPerView: 2,
    spaceBetween: 30,
    centeredSlides: true,
    // loop: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  swiperOptionM: any = {
    slidesPerView: 1.5,
    spaceBetween: 1,
    centeredSlides: true,
    // loop: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  // get swiper() {
  //   return (this.$refs.mySwiper as any).$swiper;
  // }

  routerPage() {
    this.$store.commit('setCurrentPage', 'query');
  }
  moni(item) {
    if (item) {
      return item;
    }
    return '请选择';
  }
}
