<template>
  <div class="flip" v-show="type">
    <div class="mobile">
      <img src="@/assets/images/mobile-h.png" alt="" />
      <div class="text" v-if="currentPage !== 'Video'">请竖屏观看</div>
      <div class="text" v-if="currentPage === 'Video'">点击全屏按钮<span><i><img src="@/assets/images/bofang.png" alt=""></i></span>后横屏观看</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      type: false,
    };
  },
  computed: mapState(['currentPage']),

  mounted() {
    if (window.orientation == 90 || window.orientation == -90) {
      this.type = true;
    }

    let that = this;
    window.addEventListener(
      'orientationchange',
      function() {
        that.orient();
      },
      false,
    );
  },
  methods: {
    orient() {
      if (window.orientation == 180 || window.orientation == 0) {
        this.type = false;
      }
      if (window.orientation == 90 || window.orientation == -90) {
        // 横屏
        this.type = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flip {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #000;
  .mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .text {
      color: #fff;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 15px;
        margin: 0 5px;
      }
    }
    img {
      width: 300px;
    }
  }
}
</style>
