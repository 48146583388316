import { render, staticRenderFns } from "./flipMobile.vue?vue&type=template&id=06a19bee&scoped=true&"
import script from "./flipMobile.vue?vue&type=script&lang=js&"
export * from "./flipMobile.vue?vue&type=script&lang=js&"
import style0 from "./flipMobile.vue?vue&type=style&index=0&id=06a19bee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a19bee",
  null
  
)

export default component.exports