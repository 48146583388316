

















































import { Component, Watch, Prop, Emit, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  computed: mapState(['currentPage']),
})
export default class Footer extends Vue {
  name: any = '';
  currentPage!: string;

  data() {
    return {};
  }
  load() {
    // this.name = this.$route.name;
    // console.log(this.$route.name)
  }
  // router(i) {
  //   if (i === this.$route.name) return;
  //   let router = '/' + i;
  //   this.$router.replace(router);
  // }
  created() {
    // this.load();
  }
  toRouter(i) {
    this.$store.commit('setCurrentPage', i);
  }
}
