export const colors = [
  {
    id: 1,
    name: '迈阿密蓝',
    value: '#009ecf',
  },
  {
    id: 2,
    name: '白云石',
    value: '#dfeffb',
  },
  {
    id: 3,
    name: '帕派雅橙',
    value: '#cd270a',
  },
  {
    id: 4,
    name: '月光蓝',
    value: '#31466b',
  },
  {
    id: 5,
    name: '午夜蓝',
    value: '#2c415c',
  },
  {
    id: 6,
    name: '黑玉色',
    value: '#2c415c',
  },
  {
    id: 7,
    name: '黑色',
    value: '#000000',
  },
  {
    id: 8,
    name: '玛瑙灰',
    value: '#8a8e95',
  },
  {
    id: 9,
    name: '砂石绿',
    value: '#7f8787',
  },
  {
    id: 10,
    name: '火山灰',
    value: '#587182',
  },
  {
    id: 11,
    name: '龙胆蓝',
    value: '#2a4490',
  },
  {
    id: 12,
    name: '卫红',
    value: '#f00912',
  },
  {
    id: 13,
    name: '胭脂红',
    value: '#df1a2f',
  },
  {
    id: 14,
    name: '桃红木',
    value: '#21141e',
  },
  {
    id: 15,
    name: '凌空灰',
    value: '#c8d1d5',
  },
  {
    id: 16,
    name: '哑灰',
    value: '#e7e8e6',
  },
  {
    id: 17,
    name: '海王星蓝',
    value: '#2f70ad',
  },
  {
    id: 18,
    name: '冰晶蓝',
    value: '#90cfe1',
  },
  {
    id: 19,
    name: '冰梅粉',
    value: '#bca3a4',
  },
  {
    id: 20,
    name: '细花白',
    value: '#f9f7f7',
  },
  {
    id: 21,
    name: '白色',
    value: '#ffffff',
  },
  {
    id: 22,
    name: '白云石金属漆',
    value: '#dfeffb',
  },
  {
    id: 23,
    name: '车厘子红',
    value: '#bf343f',
  },
];

export const names = [
  {
    id: 1,
    name: 'panamera',
    img: 'images/color_film/carname/panamera.png',
  },
  {
    id: 2,
    name: 'cayenne',
    img: 'images/color_film/carname/cayenne.png',
  },
  {
    id: 3,
    name: '718',
    img: 'images/color_film/carname/718.png',
  },
  {
    id: 4,
    name: '911',
    img: 'images/color_film/carname/911.png',
  },
  {
    id: 5,
    name: 'taycan',
    img: 'images/color_film/carname/taycan.png',
  },
  {
    id: 6,
    name: 'macan',
    img: 'images/color_film/carname/macan.png',
  },
];
