






































































import { userService } from '@/services/user.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Car from './Car.vue';
import CarFilled from './CarFilled.vue';

@Component({
  components: {
    Car,
    CarFilled,
  },
})
export default class Race extends Vue {
  isIpad = userService.isIpad;
  mounted() {
    console.log(this.themeType);
  }

  get themeType() {
    return this.$store.state.themeType;
  }

  carData1 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/taycan/1.png'), color: 13 },
        { url: require('@/assets/images/color_film/race/taycan/2.png'), color: 23 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/911/1.png'), color: 13 },
        { url: require('@/assets/images/color_film/race/911/2.png'), color: 12 },
      ],
    },
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/718/2.png'), color: 13 },
        { url: require('@/assets/images/color_film/race/718/1.png'), color: 12 },
      ],
    },
    {
      current: 0,
      carname: 'macan',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/macan/1.png'), color: 13 },
        { url: require('@/assets/images/color_film/race/macan/2.png'), color: 14 },
      ],
    },
  ];

  carData2 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/taycan/21.png'), color: 18 },
      ],
    },
  ];

  carData3 = [
    {
      current: 0,
      carname: 'taycan',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/taycan/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/race/taycan/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/911/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/race/911/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/718/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/race/718/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: 'panamera',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/panamera/31.png'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: 'cayenne',
      imgs: [
        { url: require('@/assets/images/color_film/race/cayenne/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/race/cayenne/32.png'), color: 21 },
      ],
    },
    {
      current: 0,
      carname: 'macan',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/macan/31.png'), color: 20 },
        { url: require('@/assets/images/color_film/race/macan/32.png'), color: 21 },
      ],
    },
  ];
  carData4 = [
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/718/41.jpg'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/911/41.jpg'), color: 20 },
        { url: require('@/assets/images/color_film/race/911/42.jpg'), color: 13 },
        { url: require('@/assets/images/color_film/race/911/43.jpg'), color: 2 },
      ],
    },
  ];
  carData5 = [
    {
      current: 0,
      carname: '718',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/718/51.jpg'), color: 20 },
      ],
    },
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/911/51.jpg'), color: 20 },
        { url: require('@/assets/images/color_film/race/911/52.jpg'), color: 21 },
        { url: require('@/assets/images/color_film/race/911/53.jpg'), color: 21 },
      ],
    },
  ];
  carData6 = [
    {
      current: 0,
      carname: '911',
      imgs: [
        //
        { url: require('@/assets/images/color_film/race/911/61.jpg'), color: 20 },
      ],
    },
  ];
}
