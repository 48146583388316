





































import { userService } from '@/services/user.service';
import { Component, Vue } from 'vue-property-decorator';
// import Footer from '@/components/footer.vue';
@Component({
  // components: { Footer },
  //   computed: mapState(['currentPage']),
})
export default class warranty extends Vue {
  isIpad = userService.isIpad;

  type = false;
  txtType = false;
  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }

  translateX() {
    this.type = true;
    let that = this;
    setTimeout(function () {
      that.txtType = true;
    }, 6500);
  }
}
