


















import { Slider } from 'vant';
Vue.use(Slider);

import { Component, Vue } from 'vue-property-decorator';
// import Footer from '@/components/footer.vue';
@Component({
  // components: { Footer },
  //   computed: mapState(['currentPage']),
})
export default class seifHealing extends Vue {
  value = 300;

  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }

  onInput(value) {
    // console.log(value);
    //   Toast('当前值：' + value);
  }
  mounted() {
    // let that = this;
    // let donghua = setInterval(function() {
    //   if (that.value < 300) {
    //     that.value = that.value + 7;
    //   } else {
    //    window.clearInterval(donghua);
    //   }
    // }, 100);
  }
}
