
















import { Component, Watch, Prop, Emit, Vue } from 'vue-property-decorator';

@Component
export default class Load extends Vue {
  @Prop(Array) list!: any;

  index: number = 0;

  @Watch('index')
  onIndexChange(t: number) {
    if (this.index === this.list.length) {
      setTimeout(() => {
        this.loadOver();
      }, 500);
    }
  }

  get progress() {
    return Math.ceil((this.index / this.list.length) * 100);
  }

  @Emit('complete')
  loadOver() {
    console.log('loadOver');
  }

  loadImg() {
    this.list.map((url) => {
      const img = new Image();
      img.onload = () => {
        this.index++;
      };
      img.src = url;
    });
  }

  created() {
    this.loadImg();
  }
}
