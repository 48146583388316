































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { colors } from '@/store/vehicle';
import { userService } from '@/services/user.service';
@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class Car extends Vue {
  isIpad=userService.isIpad
  @Prop() carData: any;

  mounted() {}

  colors = colors;

  getColor(color: number) {
    const item = this.colors.find(item => item.id === color);
    return (
      item || {
        id: 21,
        name: '白色',
        value: '#ffffff',
      }
    );
  }

  swiperOption: any = {
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: 1.5,
    coverflowEffect: {
      rotate: 60,
      stretch: -10,
      depth: 200,
      modifier: 1,
      slideShadows: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  resetShowTip() {
    setTimeout(() => {
      const index = (this.$refs.mySwiper as any).$swiper.realIndex;
      if (this.carData[index].imgs.length > 1) {
        this.showTip = true;
        return;
      }
      this.showTip = false;
    }, 30);
  }

  colorSwiperOption: any = {
    // loop: true,
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 0,
      stretch: 800,
      depth: 300,
      modifier: 2,
      slideShadows: false,
    },
  };

  showTip = true;

  // 切换图片
  changeImg(target?: number) {
    this.showTip = false;
    const index = (this.$refs.mySwiper as any).$swiper.realIndex;
    if (target !== undefined) {
      this.carData[index].current = target;
    } else {
      this.carData[index].current =
        this.carData[index].current === this.carData[index].imgs.length - 1 ? 0 : this.carData[index].current + 1;
    }

    // console.log((this.$refs[`colorSwiper${index}`] as any)[0].$swiper);

    // (this.$refs[`colorSwiper${index}`] as any)[0].$swiper.slideTo(this.carData[index].current);
  }
}
