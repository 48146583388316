import '@/styles/index.scss'; // global css
import Vue from 'vue';
import VueJsonp from 'vue-jsonp';
import App from './App.vue';
import './directive/index';
import router from './router';
import store from './store';
// import Vant from 'vant';
import 'vant/lib/index.css';
import vueEaser from 'vue-eraser';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
// import vconsole from 'vconsole';


Vue.use(VueAwesomeSwiper /* { default options with global component } */);
// new vconsole();
// require styles
// import 'swiper/swiper.scss'

// Vue.use(Vant);
Vue.config.productionTip = false;
Vue.use(VueJsonp);
Vue.use(vueEaser);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
