
















declare const Aliplayer: any;
let startY: any = 0;

import { mapState } from 'vuex';
// import flipMobile from '@/components/flipMobile.vue';

import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    // flipMobile,
  },
})
export default class Video extends Vue {
  height = '500px';
  type = false;
  mobileH = false;
  mounted() {
    let that = this;
    if (window.innerWidth < 750) {
      this.height = '200px';
    }

    // that.init();

    let elevideo = document.querySelector('video');
    (elevideo as any).addEventListener(
      'ended',
      function () {
        //结束
        that.mobileH = true;
      },
      false,
    );
    window.addEventListener(
      'orientationchange',
      function () {
        that.orient();
      },
      false,
    );
  }

  init() {
    let h = 'calc(645 / 301 * 100vw)';
    let that = this;
    // id: 'player-con',
    //   source: require('@/assets/video.mp4'),
    //   width: '100%',
    //   height: this.height,
    //   autoplay: false,
    //   isLive: false,
    //   cover: require('@/assets/images/video.jpg'),

    var player = new Aliplayer(
      {
        id: 'player-con',
        source: require('@/assets/video.mp4'),
        width: '100%',
        height: this.height,
        autoplay: false,
        isLive: false,
        cover: require('@/assets/images/video.jpg'),
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: 'click',
        showBarTime: 5000,
        useH5Prism: true,
        skinLayout: [
          {
            name: 'bigPlayButton',
            align: 'blabs',
            x: 30,
            y: 80,
          },
          {
            name: 'controlBar',
            align: 'blabs',
            x: 0,
            y: 0,
            children: [
              {
                name: 'progress',
                align: 'blabs',
                x: 0,
                y: 44,
              },
              {
                name: 'playButton',
                align: 'tl',
                x: 15,
                y: 12,
              },
              {
                name: 'timeDisplay',
                align: 'tl',
                x: 10,
                y: 7,
              },
              {
                name: 'fullScreenButton',
                align: 'tr',
                x: 10,
                y: 12,
              },
              {
                name: 'volume',
                align: 'tr',
                x: 5,
                y: 10,
              },
            ],
          },
        ],
      },
      function (player) {
        console.log('The player is created');
      },
    );
  }
  bofang() {
    let myVideo: any = document.querySelector('video');
    if (myVideo.paused) {
      myVideo.play();
    } else {
      myVideo.pause();
    }
  }

  touchstart(e) {
    e.preventDefault();
    startY = e.touches[0].clientY;
  }
  touchend(e) {
    e.preventDefault();
    // 上滑
    if (startY > e.changedTouches[0].clientY + 10) {
      this.$store.commit('setCurrentPage', 'paint');
    }
    if (startY < e.changedTouches[0].clientY - 10) {
      this.$store.commit('setCurrentPage', 'Home');
    }
  }

  orient() {
    if (window.orientation == 180 || window.orientation == 0) {
      this.type = false;
    }
    if (window.orientation == 90 || window.orientation == -90) {
      // 横屏
      this.type = true;
    }
  }

  routertop() {
    this.$store.commit('setCurrentPage', 'Home');
  }
}
