












































































import { Component, Vue } from 'vue-property-decorator';
import Home from '@/components/Home.vue';
import Load from '@/components/Load.vue';
import VideoPage from '@/components/video.vue';
import seifHealing from '@/components/selfHealing.vue';
import yellowingResistance from '@/components/yellowingResistance.vue';
import antifouling from '@/components/antifouling.vue';
import durable from '@/components/durable.vue';
import paint from '@/components/paint.vue';
import warranty from '@/components/warranty.vue';
import FormBox from '@/components/formBox.vue';
import Skip from '@/components/Skip.vue';
import Footer from '@/components/footer.vue';
import query from '@/components/query.vue';
import result from '@/components/result.vue';
import flipMobile from '@/components/flipMobile.vue';
import Theme from '@/components/Theme.vue';
import Detail from '@/components/Detail.vue';
import Hydrophobic from '@/components/Hydrophobic.vue';
import Coating from '@/components/Coating.vue';

import { mapState } from 'vuex';
import scratchCard from 'lzy-scratch-card';

@Component({
  components: {
    Home,
    Load,
    VideoPage,
    seifHealing,
    yellowingResistance,
    paint,
    antifouling,
    warranty,
    durable,
    FormBox,
    Skip,
    Footer,
    query,
    result,
    flipMobile,
    Theme,
    Detail,
    Hydrophobic,
    Coating,
    scratchCard,
  },
  computed: mapState(['currentPage']),
})
export default class Index extends Vue {
  inited: boolean = false;
  currentPage!: string;
  type = 'ipad';
  result = '';

  imgIpad = require('@/assets/images/antifouling-bg-s.jpg');
  img = require('@/assets/images/mobile/antifouling-bg-s.jpg');

  get imgs() {
    // return mapState(['imgs']);
    return this.$store.state.imgs;
  }

  getMobile() {
    let ua = navigator.userAgent;

    let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/),
      isMobile = isIphone || isAndroid;

    if (isMobile) {
      this.type = 'mobile';
    } else {
    }
  }

  //开始刮时的回调函数
  startCallback() {}
  //刮刮乐刮层全部清除后的回调函数
  clearCallbackUp() {}

  onComplete() {
    this.inited = true;
    console.log(`completed`);

    // this.$store.commit('setCurrentPage', 'Theme');
  }

  created() {
    // console.log(this.imgs);
    this.getMobile();
    // this.$store.commit('setCurrentPage', 'query');
  }
}
