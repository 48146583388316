export let imgs = [
  require(`@/assets/images/logo.jpg`),
  require(`@/assets/images/load.png`),
  require(`@/assets/images/antifouling.png`),
  require(`@/assets/images/antifouling-bg.jpg`),
  require(`@/assets/images/antifouling-bg-s.jpg`),
  require(`@/assets/images/color_film/btn-book.png`),
  require(`@/assets/images/color_film/btn-home.png`),
  require(`@/assets/images/color_film/btn-query.png`),
  require(`@/assets/images/antifouling-tishi.png`),
  require(`@/assets/images/brightness.png`),
  require(`@/assets/images/durable.jpg`),
  require(`@/assets/images/durable-s.jpg`),
  require(`@/assets/images/durable-tishi.png`),
  require(`@/assets/images/durable-txt.png`),
  require(`@/assets/images/form-bg.jpg`),
  require(`@/assets/images/form-txt.png`),
  require(`@/assets/images/home_bg.jpg`),
  require(`@/assets/images/home-text.png`),
  require(`@/assets/images/mask.png`),
  require(`@/assets/images/mask1.png`),
  require(`@/assets/images/mask2.png`),
  require(`@/assets/images/paint-bg.jpg`),
  require(`@/assets/images/paint-tishi.png`),
  require(`@/assets/images/paint-txt.png`),
  require(`@/assets/images/rain.png`),
  require(`@/assets/images/seif_healing_bg.jpg`),
  require(`@/assets/images/seif_healing_bg_s.jpg`),
  require(`@/assets/images/select.png`),
  require(`@/assets/images/skider.png`),
  require(`@/assets/images/skip.png`),
  require(`@/assets/images/slide.png`),
  require(`@/assets/images/Tips.png`),
  require(`@/assets/images/up.png`),
  require(`@/assets/images/video.jpg`),
  require(`@/assets/images/warranty-bg.jpg`),
  require(`@/assets/images/warranty-car.png`),
  require(`@/assets/images/warranty-tishi.png`),
  require(`@/assets/images/warranty-txt.png`),
  require(`@/assets/images/yellowing-resistance.jpg`),
  require(`@/assets/images/yellowing-resistance-txt.png`),
  require(`@/assets/images/mobile-h.png`),
  require(`@/assets/images/durable-tishi-bg.png`),
  require(`@/assets/images/paint-bg-s.png`),
  require(`@/assets/images/warranty-tishi-bg.png`),
  require(`@/assets/images/wheel.png`),

  require(`@/assets/images/footer/icon1.png`),
  require(`@/assets/images/footer/icon1s.png`),
  require(`@/assets/images/footer/icon2.png`),
  require(`@/assets/images/footer/icon2s.png`),
  require(`@/assets/images/footer/icon3.png`),
  require(`@/assets/images/footer/icon3s.png`),
  require(`@/assets/images/footer/icon4.png`),
  require(`@/assets/images/footer/icon4s.png`),
  require(`@/assets/images/footer/icon5.png`),
  require(`@/assets/images/footer/icon5s.png`),
  require(`@/assets/images/footer/icon6.png`),
  require(`@/assets/images/footer/icon6s.png`),

  require(`@/assets/images/mobile/addres.png`),
  require(`@/assets/images/mobile/antifouling-bg.jpg`),
  require(`@/assets/images/mobile/antifouling-bg-s.jpg`),
  require(`@/assets/images/mobile/button.png`),
  require(`@/assets/images/mobile/button2.png`),
  require(`@/assets/images/mobile/durable.jpg`),
  require(`@/assets/images/mobile/durable-s.jpg`),
  require(`@/assets/images/mobile/form-bg.jpg`),
  require(`@/assets/images/mobile/home-bg.jpg`),
  require(`@/assets/images/mobile/home-txt.png`),
  require(`@/assets/images/mobile/paint-bg.jpg`),
  require(`@/assets/images/mobile/query-bg.jpg`),
  require(`@/assets/images/mobile/query-txt.png`),
  require(`@/assets/images/mobile/result-banner.jpg`),
  require(`@/assets/images/mobile/seif-healing-bg.jpg`),
  require(`@/assets/images/mobile/seif-healing-bg-s.jpg`),
  require(`@/assets/images/mobile/warranty-bg.jpg`),
  require(`@/assets/images/mobile/paint-bg-s.png`),
  require(`@/assets/images/mobile/video-bg.jpg`),

];
