





















































import { APP_CONFIG } from '@/config';
import { userService } from '@/services/user.service';
import Axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

import { mapState } from 'vuex';

// const plist = require('@/assets/addres.json');

@Component({
  computed: mapState(['cityCode']),
})
export default class result extends Vue {
  isIpad = userService.isIpad;

  cityCode!: string;
  phone = '';
  plist: any = [];

  addres = [];
  getAddres(code) {
    for (let i = 0; i < this.plist.length; i++) {
      if (code === this.plist[i].code) {
        this.addres = this.plist[i];
        this.phone = 'tel:' + this.plist[i].phone;
      }
    }
  }
  routertop() {
    this.$store.commit('setCurrentPage', 'query');
  }
  created() {
    Axios.get('https://event.fnws.top/Porsche-Static/dealers.json').then(res => {
      this.plist = res.data;
      this.getAddres(this.cityCode);
    });
  }

  get queryFrom() {
    return this.$store.state.queryFrom;
  }
  go2Book() {
    window.location.href = APP_CONFIG.bookLink;
  }

  go2Home() {
    this.$store.commit('setCurrentPage', 'Home');
  }
}
