import Vue from 'vue';
import Vuex from 'vuex';
import { imgs } from './assets';

Vue.use(Vuex);

const state = {
  imgs,
  step: 0,
  currentPage: 'Home',
  footerType: 'seifHealing',
  cityCode: '',
  themeType: '',
  queryFrom: '',
};

export default new Vuex.Store({
  state,
  mutations: {
    setCurrentPage(state: any, pageName: string) {
      state.currentPage = pageName;
    },
    setThemeType(state: any, typeName: string) {
      state.themeType = typeName;
    },
    setQueryFrom(state: any, queryFrom: string) {
      state.queryFrom = queryFrom;
    },
    city(state: any, code: string) {
      state.cityCode = code;
    },
  },
  actions: {},
  modules: {},
});
