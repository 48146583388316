<template>
  <div class="yellowing-resistance" v-onDelay="100">
    <div class="skip" @click="routertop">
      <span>返回</span>
    </div>
    <div class="bg"></div>
    <div class="bg-s" :style="{ clip: `rect(0px, ${value / 10}vw, 100vh, 0)` }">
      <img src="@/assets/images/yellowing-resistance-txt.png" alt="" />
    </div>
    <div class="skider">
      <van-slider v-model="value" active-color="#ccc" @input="onInput" :min="0" :max="1000" />
    </div>
    <div class="tishi" v-if="value === 500" v-onDelay="1000">
      <img src="@/assets/images/Tips.png" alt="" />
    </div>
    <div class="facial-mask" v-onDelay="1000">
      <img class="mask1" :class="{ type: value > 500 }" src="@/assets/images/mask1.png" alt="" />
      <img class="mask2" :class="{ type: value < 500 }" src="@/assets/images/mask2.png" alt="" />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { Slider } from 'vant';
Vue.use(Slider);

import { Component, Vue } from 'vue-property-decorator';
// import Footer from '@/components/footer.vue';
@Component({
  // components: { Footer },
})
export default class yellowingResistance extends Vue {
  value = 500;
  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }
  onInput(value) {
    // console.log(value);
    //   Toast('当前值：' + value);
  }
}
</script>

<style lang="scss" scoped>
.yellowing-resistance {
  opacity: 0;
  transition: 1.5s ease;
  &.on {
    opacity: 1;
  }
  .skip {
    left: 20px;
    span {
      color: #000;
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  .bg {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/yellowing-resistance.jpg') no-repeat center;
    background-size: 100%;
    position: relative;
    filter: sepia(60%);
    @media (max-width: 670px) {
      background: url('../assets/images/mobile/yellowing-resistance.jpg') no-repeat;
      background-size: cover;
    }
  }
  .bg-s {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: url('../assets/images/yellowing-resistance.jpg') no-repeat center;
    background-size: 100%;
    top: 0;
    left: 0;
    @media (max-width: 670px) {
      background: url('../assets/images/mobile/yellowing-resistance.jpg') no-repeat;
      background-size: cover;
    }
    img {
      position: absolute;
      width: 30vw;
      bottom: 30vh;
      left: 10vw;
      @media (max-width: 670px) {
        width: 50vw;
      }
    }
  }
  .skider {
    position: fixed;
    bottom: 23%;
    left: 0;
    width: 100vw;
  }
  .tishi {
    position: fixed;
    bottom: 16%;
    left: 50%;
    width: 200px;
    opacity: 0;
    transition: 1.5s ease;
    @media (max-width: 670px) {
      width: 140px;
      bottom: 18%;
    }
    &.on {
      opacity: 1;
      animation: myfirst 5s;
    }
  }
  .facial-mask {
    position: fixed;
    top: 17%;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translate(0, 10px);
    transition: 1.5s ease;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.on {
      opacity: 1;
      transform: translate(0, 0);
    }
    img {
      z-index: 100;
      width: 10vw;
      opacity: 0;
      transition: 1.2s ease;

      &.type {
        opacity: 1;
      }
      &.mask1 {
        width: 11.45vw;
      }
    }
    @media (max-width: 670px) {
      top: 25%;

      img {
        width: 20vw;
        &.mask1 {
          width: 22.9vw;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.yellowing-resistance {
  .van-slider__bar,
  .van-slider {
    background: none !important;
  }
  .van-slider__button {
    width: 59px;
    height: 26px;
    background: url('../assets/images/skider.png');
    background-size: 100%;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 200vh;
      background: #fff;
      display: block;
      bottom: -100vh;
      left: 50%;
    }
  }
}
</style>
