






















import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/footer.vue';
import { rain } from '@/services/rain.js';
import { userService } from '@/services/user.service';
// import { dat } from '@/services/dat.gui.min.js';

@Component({
  components: { Footer },
})
export default class durable extends Vue {
  isIpad = userService.isIpad;

  type = true;
  proof = true;
  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }
  dayType() {
    this.type = !this.type;
    this.proof = false;
    //     let that = this;
    //     if (!this.proof) return;
    //     this.proof = false;
    //     setTimeout(function() {
    //       that.proof = true;
    //       that.type = !that.type;
    //     }, 1500);
  }
  //   rain() {}
  mounted() {
    // setTimeout(function () {
    //   rain().init();
    // }, 1000);
    // rain();
    // new Weather({
    //   id: 'canvas',
    //   weatherType: 'rain',
    //   num: 100,
    // });
  }
}
