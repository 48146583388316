<template>
  <div class="antifouling" v-onDelay="200">
    <div class="skip" @click="routertop">
      <span>返回</span>
    </div>

    <div class="bg">
      <!-- style="position: fixed; top: 0; left: 0; display: block; " -->
      <div v-if="imgType" style="position: absolute; top: 0; left: 0; z-index: 2; width: 100%; height: 100%">
        <scratch-card
          elementId="scratch"
          :width="width"
          :height="height"
          :moveRadius="15"
          :ratio="0.1"
          :startCallback="startCallback"
          :clearCallback="clearCallbackUp"
          :coverImg="imgS"
          :result="result"
        ></scratch-card>
      </div>
      <img
        :src="img"
        alt=""
        style="position: absolute; top: 0; left: 0; display: block; width: 100vw; opacity: 1; pointer-events: none"
        ref="image"
        @load="getImageSize"
      />

      <!-- <vue-eraser
        :class="{ on: !eraser }"
        ref="vueEraser"
        :size="70"
        :coverSrc="imgS"
        :resultSrc="img"
        :completeFunction="completeFunction"
        :progressFunction="progressFunction"
        :completeRatio="num"
      ></vue-eraser> -->
      <div class="tishi" v-if="type">
        <div class="img">
          <img src="@/assets/images/antifouling-tishi.png" alt="" />
          <div v-onDelay="100" class="img2"></div>
        </div>
      </div>
    </div>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
// import Footer from '@/components/footer.vue';
import vueEaser from 'vue-eraser';
import scratchCard from 'lzy-scratch-card';
import { baseIpad, base } from '../store/base';

@Component({
  components: { scratchCard },
})
export default class antifouling extends Vue {
  imgS = baseIpad;
  img = require('@/assets/images/antifouling-bg.jpg');
  width = '100%';
  height = '100%';
  imgType = false;

  result = '';

  num = 0.1;
  type = true;
  eraser = false;
  routertop() {
    this.$store.commit('setCurrentPage', 'Video');
  }

  //开始刮时的回调函数
  startCallback() {
    this.type = false;
  }
  //刮刮乐刮层全部清除后的回调函数
  clearCallbackUp() {
    this.result = '';
  }
  getImageSize() {
    const image = this.$refs.image;
    const rect = image.getBoundingClientRect();
    this.width = rect.width + 'px';
    this.height = rect.height + 'px';
    this.imgType = true;
    console.log('Rendered image width:', this.width);
    console.log('Rendered image height:', this.height);
  }

  created() {
    if (window.innerWidth < 670) {
      this.imgS = base;
      this.img = require('@/assets/images/mobile/antifouling-bg.jpg');
    }
  }
  aa() {
    console.log('111');
  }
  completeFunction() {
    this.eraser = true;
    // this.$refs.vueEraser.clear();
  }
  progressFunction() {
    this.type = false;
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 670px) {
  .antifouling {
    .skip {
      span {
        color: #000;
      }
    }
  }
}
.antifouling {
  opacity: 0;
  transition: 1.5s ease;
  &.on {
    opacity: 1;
  }

  .skip {
    left: 20px;
    span {
      color: #fff;
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  .vue-eraser {
    opacity: 0;
    transition: 0.5s;
    &.on {
      opacity: 1;
    }
  }
  .bg {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/antifouling-bg-s.jpg') no-repeat top;
    background-size: 100% auto;

    position: relative;
    .tishi {
      position: fixed;
      right: 20%;
      top: 70%;
      z-index: 10;
      width: 50%;
      .img {
        position: relative;
        .img2 {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url('../assets/images/antifouling-tishi-bg.png') no-repeat center;
          background-size: 100%;
          opacity: 0;
          transition: 1.2s ease;

          &.on {
            opacity: 1;
            animation: myfirst2 5s infinite;
          }
        }
      }
    }
  }
  @media (max-width: 670px) {
    .bg {
      background: url('../assets/images/mobile/antifouling-bg.jpg') no-repeat;
      background-size: 100%;
      .tishi {
        width: 100%;
        top: 55%;
        left: 0;
      }
    }
  }
}
</style>

