import { imgs } from '../store/assets';

class UtilsService {
  window = window as any;

  getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getQueryString(name: string) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return '';
  }

  sortChinese(arr) {
    return arr.sort((item1, item2) => {
      return item1.localeCompare(item2, 'zh-CN');
    })
  }

  sendEvent(type) {
    if (type === 't') this.window.ga('send', 'event', 'nav', 'share', 'timeline');
    else this.window.ga('send', 'event', 'nav', 'share', 'message');
  }

  // gaEvent(params,) {
  //   if (this.window.ga) {
  //     this.window.ga('send', 'event', category, params);
  //   }
  //   if (this.window.stm_clicki) {
  //     this.window.stm_clicki('send', 'event', category, params);
  //   }
  // }

  // gaPage(params) {
  //   if (window.ga) {
  //     ga('send', 'pageview', params);
  //   }
  //   if (window.stm_clicki) {
  //     stm_clicki('send', 'pageview', params);
  //   }
  // }


  /**
 * 声音
 * @param {String} url	链接
 * @param {Boolean} autoPlay	自动播放
 * @param {Boolean} loop	循环
 * @param {Function} onPlay	当播放时
 * @param {Function} onPause	当暂停时
 * @param {Function} onEnded	当结束时
 * 
 * 
 */
  lAudio(url: string, autoPlay: boolean, loop: boolean, onPlay: any, onPause: any, onEnded: any) {
    const audio = document.createElement("audio");
    audio.src = url;
    audio.loop = false;

    audio.autoplay = autoPlay;
    if (audio.autoplay) {
      document.addEventListener("WeixinJSBridgeReady", function () {
        audio.play();
      });
    }
    if (onPlay) audio.addEventListener("play", onPlay);
    if (onPause) audio.addEventListener("pause", onPause);
    audio.addEventListener("ended", () => {
      if (onEnded) onEnded();

      if (loop) {
        audio.play();
      }
    })

    // audio.restart = function () {
    //   audio.currentTime = 0;
    //   audio.play();
    // }

    // audio.originalPlay = audio.play

    // audio.play = function () {
    //   $(audio).unbind("play", loadPlay)
    //   audio.originalPlay()
    // }

    // //调用play来实现加载
    // audio.startLoad = function () {
    //   $(audio).bind("play", loadPlay)
    //   audio.originalPlay()

    // }
    // function loadPlay() {
    //   audio.pause()
    // }

    return audio;
  }

  // assets 预加载
  reloadAssets() {
    // css3 img preload, work with gfp.js by nodejs
    const createCss = () => {
      let backgroundCss = '';
      for (let i = 0; i < imgs.length; i++) {
        let u = imgs[i];
        let url = 'url(' + u + ') no-repeat 0 0 / 0';
        if ((i + 1) == imgs.length) url += ';\n';
        else url += ',\n';
        backgroundCss += url;
        // console.log(backgroundCss);
      }
      let result = 'background:\n' + backgroundCss;
      return result;
    };
    // console.log(css());

    let head = document.head || document.getElementsByTagName('head')[0];
    let style = document.createElement('style');
    let css = `.preload-images { ${createCss()} }`;

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }
}

export const utilsService = new UtilsService();
