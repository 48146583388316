var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.inited && _vm.currentPage === 'Home')?_c('Home'):_vm._e(),_c('Load',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inited),expression:"!inited"}],attrs:{"list":_vm.imgs},on:{"complete":_vm.onComplete}}),(_vm.currentPage === 'Video')?_c('VideoPage'):_vm._e(),(_vm.currentPage === 'seifHealing')?_c('seifHealing'):_vm._e(),(_vm.currentPage === 'yellowingResistance')?_c('yellowingResistance'):_vm._e(),(_vm.currentPage === 'paint')?_c('paint'):_vm._e(),(_vm.currentPage === 'antifouling')?_c('antifouling'):_vm._e(),(_vm.currentPage === 'durable')?_c('durable'):_vm._e(),(_vm.currentPage === 'warranty')?_c('warranty'):_vm._e(),(_vm.currentPage === 'Hydrophobic')?_c('Hydrophobic'):_vm._e(),(_vm.currentPage === 'Coating')?_c('Coating'):_vm._e(),(_vm.currentPage === 'form')?_c('FormBox'):_vm._e(),(
      _vm.currentPage !== 'form' &&
      _vm.currentPage !== 'Video' &&
      _vm.currentPage !== 'query' &&
      _vm.currentPage !== 'result' &&
      _vm.currentPage !== 'Theme' &&
      _vm.currentPage !== 'Detail' &&
      _vm.currentPage !== 'Home'
    )?_c('Skip'):_vm._e(),(_vm.currentPage === 'query')?_c('query'):_vm._e(),(_vm.currentPage === 'result')?_c('result'):_vm._e(),(_vm.type === 'mobile')?_c('flipMobile'):_vm._e(),(_vm.currentPage === 'Theme')?_c('Theme'):_vm._e(),(_vm.currentPage === 'Detail')?_c('Detail'):_vm._e(),(
      _vm.currentPage === 'seifHealing' ||
      _vm.currentPage === 'yellowingResistance' ||
      _vm.currentPage === 'paint' ||
      _vm.currentPage === 'antifouling' ||
      _vm.currentPage === 'warranty' ||
      _vm.currentPage === 'durable' ||
      _vm.currentPage === 'Hydrophobic' ||
      _vm.currentPage === 'Coating'
    )?_c('Footer'):_vm._e(),_c('div',{staticStyle:{"position":"absolute","top":"100vw","left":"100vh","opacity":"0","pointer-events":"none"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }